let backendOpIP = "https://psifos.labs.clcert.cl/psifos/api/app"
let backendInfoIp = "https://psifos.labs.clcert.cl/psifos/api/public"
let frontIP = "https://psifos.labs.clcert.cl/"


backendOpIP = backendOpIP !== "APP_BACKEND_OP_URL" ? backendOpIP : "http://localhost:8000"
backendInfoIp = backendInfoIp !== "APP_BACKEND_INFO_URL" ? backendInfoIp : "http://localhost:8001"
frontIP = frontIP !== "APP_FRONTEND_URL" ? frontIP : "http://localhost:3000/"


export { backendOpIP, backendInfoIp, frontIP };
