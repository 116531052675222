

function EndConsult(props){


    return(
        <div>Termino</div>
    )
}

export default EndConsult;