function ElectionCode() {
  return (
    <div>
      <footer className="footer">
        <div
          className="container has-text-centered"
          style={{ marginTop: "0.5rem" }}
        >
          <div className="columns is-centered" style={{ marginBottom: "0" }}>
            <p className="mb-0">SEGURIDAD ∙ TRANSPARENCIA ∙ VERIFICACIÓN</p>
          </div>
        </div>
      </footer>
      <section className="hero py-2">
        
      </section>
    </div>
  );
}

export default ElectionCode;
