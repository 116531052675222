
function LoadPage(props) {
  return (
    <div id="load-image">
      <div className="spinner-animation"></div>
    </div>
  );
}

export default LoadPage;
